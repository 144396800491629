exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-drinks-js": () => import("./../../../src/pages/drinks.js" /* webpackChunkName: "component---src-pages-drinks-js" */),
  "component---src-pages-food-js": () => import("./../../../src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-private-dining-js": () => import("./../../../src/pages/private-dining.js" /* webpackChunkName: "component---src-pages-private-dining-js" */),
  "component---src-pages-private-dining-test-js": () => import("./../../../src/pages/private-dining-test.js" /* webpackChunkName: "component---src-pages-private-dining-test-js" */),
  "component---src-pages-reservations-js": () => import("./../../../src/pages/reservations.js" /* webpackChunkName: "component---src-pages-reservations-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-whats-on-js": () => import("./../../../src/pages/whats-on.js" /* webpackChunkName: "component---src-pages-whats-on-js" */),
  "component---src-templates-event-template-index-js": () => import("./../../../src/templates/EventTemplate/index.js" /* webpackChunkName: "component---src-templates-event-template-index-js" */)
}

